document.addEventListener("DOMContentLoaded", function() {

    let map = L.map('map', {
        gestureHandling: L.Browser.mobile
    });
    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png').addTo(map);

    let importantMarker = L.marker([49.8435, 7.8662]).addTo(map).bindPopup('Bad Kreuznach');
    let group = new L.featureGroup([
        importantMarker,
        L.marker([49.811, 7.5237]).addTo(map).bindPopup('Simmertal'),
        L.marker([49.7772, 7.6179]).addTo(map).bindPopup('Meddersheim'),
        L.marker([49.7829, 7.6499]).addTo(map).bindPopup('Bad Sobernheim'),
        L.marker([49.7068, 7.6702]).addTo(map).bindPopup('Meisenheim'),
        L.marker([49.7343, 7.6863]).addTo(map).bindPopup('Rehborn'),
        L.marker([49.8971, 7.6744]).addTo(map).bindPopup('Münchwald'),
        L.marker([49.8175, 7.7188]).addTo(map).bindPopup('Waldböckelheim'),
        L.marker([49.7837, 7.7397]).addTo(map).bindPopup('Duchroth'),
        L.marker([49.9445, 7.7764]).addTo(map).bindPopup('Stromberg'),
        L.marker([49.8629, 7.8268]).addTo(map).bindPopup('Hargesheim'),
        L.marker([49.8088, 7.8448]).addTo(map).bindPopup('Bad Münster am Stein-Ebernburg'),
        L.marker([49.7772, 7.8934]).addTo(map).bindPopup('Fürfeld'),
        L.marker([49.8254, 7.9009]).addTo(map).bindPopup('Hackenheim'),
        L.marker([49.8946, 7.9007]).addTo(map).bindPopup('Langenlonsheim'),
    ]);

    map.fitBounds(group.getBounds(), {paddingTopLeft: [0, 35]});
    importantMarker.openPopup();

    L.Control.Center = L.Control.extend({
        onAdd: function(map) {
            let leafletBars = document.getElementsByClassName("leaflet-bar");
            if (leafletBars.length == 1) {
                let leafletBar = leafletBars[0];
                var anchor = L.DomUtil.create('a', 'leaflet-control-zoom-out d-flex justify-content-center bg-white');
                anchor.href = "#";
                anchor.title = "Center";
                anchor.innerHTML = '<span class="material-icons-outlined d-flex my-auto">crop_free</span>';
                anchor.addEventListener('click', function(e) {
                    e.preventDefault();
                    e.stopPropagation();
                    map.fitBounds(group.getBounds(), {paddingTopLeft: [0, 35]});
                    return false;
                });
                leafletBar.appendChild(anchor);
            }
        }
    });

    (new L.Control.Center({})).addTo(map);
});